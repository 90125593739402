import InputField from "components/fields/InputField";
import PasswordInputField from "components/fields/PasswordInputField";
import Loading from "components/loading";
import { useSignupMutation } from "features/auth/authApiSlice";
import { setCredentials } from "features/auth/authSlice";
import { useEffect, useState } from "react";
import { MdError } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [signup, { isLoading }] = useSignupMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const data = await signup({
        email,
        password,
        re_password: confirmPassword,
      }).unwrap();
      console.log("signup data", data);

      dispatch(
        setCredentials({ email: data.email, accessToken: "", refreshToken: "" })
      );
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      navigate(`/auth/verify-email/${email}`);
    } catch (error) {
      if (error.status === 400) {
        navigate(`/auth/verify-email/${email}`);
      }
      console.log("error", error);
      setErrMsg("Signup Failed");
    }
  };

  useEffect(() => {
    setErrMsg("");

    return () => {};
  }, [email, password, confirmPassword]);

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
  }, []);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign up section */}
      <div className="w-full max-w-full flex-col items-center md:mt-[10vh] md:pl-4 lg:mx-32 lg:pl-0 3xl:mx-44">
        <h4 className="mb-2.5 font-poppins text-5xl font-bold text-blue-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-800 dark:text-gray-300">
          Sign up to access the dashboard
        </p>
        {isLoading ? (
          <Loading />
        ) : (
          <form onSubmit={handleSignUp}>
            {errMsg && (
              <div className="my-2 flex w-full items-center gap-2 rounded-lg bg-red-400 py-3 px-3">
                <MdError color="white" />
                <p className="text-sm text-white">{errMsg}</p>
              </div>
            )}
            {/* UserName */}
            {/* <InputField
            id="userName"
            variant="auth"
            extra="mb-3"
            label="User Name*"
            placeholder="example@gmail.com"
            type="text"
            onChange={setUserName}
            value={userName}
          /> */}
            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email Address"
              placeholder="example@gmail.com"
              id="email"
              type="text"
              onChange={setEmail}
              value={email}
              required
            />

            {/* Password */}
            <PasswordInputField
              variant="auth"
              label="Password"
              placeholder="Min. 8 characters"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p className="mb-4 ml-1 text-sm text-gray-600 dark:text-gray-300">
              Must have at least two characters, and don't start with number
            </p>

            {/* Confirm Password */}
            <PasswordInputField
              variant="auth"
              label="Confirm Password"
              placeholder="Min. 8 characters"
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />

            {/* Checkbox */}

            <button
              className="linear mt-5 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
            >
              Sign Up
            </button>
            <div className="mt-4 text-center">
              <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                Have an Account!
              </span>
              <Link
                to="/auth/sign-in"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                Login
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
