import { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

function useOutsideAlerter(ref, setX) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

function HigherOffDropdown({
  higherOfficals,
  title,
  extra,
  id = "dropdown",
  onClick,
  value,
}) {
  const wrapperRef = useRef(null);
  const [openWrapper, setOpenWrapper] = useState(false);
  const [filteredData, setFilteredData] = useState(higherOfficals);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== "") {
      const filtered = higherOfficals.filter(
        (expert) =>
          expert?.profile?.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          expert?.profile?.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
      return;
    }
    setFilteredData(higherOfficals);
  };

  useEffect(() => {
    setFilteredData(higherOfficals);
  }, [higherOfficals]);

  return (
    <div
      ref={wrapperRef}
      className={`relative flex flex-grow lg:w-[64%] lg:flex-grow-0 !${extra}`}
    >
      <div
        onMouseDown={() => setOpenWrapper(!openWrapper)}
        className="flex flex-grow cursor-pointer items-center justify-between rounded-lg bg-[#FAFAFA] p-4 "
      >
        {value ? (
          <div className="flex items-center gap-5">
            <img
              src={value.profile?.file}
              alt={value.profile?.first_name}
              className="h-8 w-8 rounded-full"
            />
            <p>{`${value.profile?.first_name} ${value.profile?.last_name}`}</p>
          </div>
        ) : (
          <p className="text-gray-400">
            {title ? title : "Select Higher Official"}
          </p>
        )}
        <FaChevronDown size={10} />
      </div>
      <div
        id={id}
        className={`absolute top-16 z-20 w-full  rounded-lg border bg-white shadow  
        ${openWrapper ? "scale-200" : "scale-0"}`}
      >
        <input
          type="text"
          placeholder="Search"
          className="mx-8 mt-2 mb-4 h-10 w-[80%] rounded-lg border border-gray-200 px-3 text-gray-900 placeholder:text-gray-900"
          onChange={handleSearch}
        />
        <ul className="mx-5 flex flex-col gap-2">
          {filteredData?.map((item, index) => (
            <li
              key={index}
              className="flex cursor-pointer items-center gap-5 rounded-md p-2 px-5 hover:bg-blue-600 hover:text-white"
              onClick={() => {
                onClick(item);
                setOpenWrapper(false);
              }}
            >
              <img
                src={item.profile?.file}
                alt={item.profile?.first_name}
                className="h-8 w-8 rounded-full"
              />
              <p>{`${item.profile?.first_name} ${item.profile?.last_name}`}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
export default HigherOffDropdown;
