import { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import {
  IoAddCircleOutline,
  IoChevronDownCircleOutline,
} from "react-icons/io5";
import ActivityRow from "views/admin/expert/components/ActivityRow";
import ActivityRowForm from "views/admin/expert/components/ActivityRowForm";
import MilestoneRowForm from "views/admin/expert/components/MilestoneRowForm";
import MilestoneRow from "views/admin/ceo/components/MilestoneRow";
import Loading from "components/loading";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import { useWindowSize } from "@uidotdev/usehooks";
import ActivityRowCard from "views/admin/expert/components/ActivityRowCard";
import { MdEdit } from "react-icons/md";

function ProjectMilestone({
  data,
  projectName,
  removeMilestone,
  handleMilestoneSave,
  handleActivitySave,
  loading,
  index,
}) {
  const size = useWindowSize();
  const [showMilestone, setShowMilestone] = useState(false);
  const [showMileForm, setShowMileForm] = useState(false);
  const [milestoneSaved, setMilestoneSaved] = useState(false);
  const [editMilestone, setEditMilestone] = useState(false);
  // activities
  const [showActivity, setShowActivity] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [activityDesc, setActivityDesc] = useState("");
  const [activityStatus, setActivityStatus] = useState("not started");
  const [activityStart, setActivityStart] = useState("");
  const [activityEnd, setActivityEnd] = useState("");
  const [activityBudget, setActivityBudget] = useState("");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const activityFormId = `activityForm ${data.id ? data.id : Math.random()}`;

  const handleActitvitySubmit = async (e) => {
    e.preventDefault();
    await handleActivitySave(
      {
        activityName,
        activityDesc,
        activityStatus,
        activityStart,
        activityEnd,
        activityBudget,
      },
      data?.id,
      selectedActivity ? selectedActivity.id : null
    ).then(async (res) => {
      if (res) {
        setActivityName("");
        setActivityStatus("not started");
        setActivityStart("");
        setActivityEnd("");
        setActivityBudget("");
        setActivityDesc("");
        setShowActivityForm(false);
        setShowActivity(true);
      }
    });
  };

  useEffect(() => {
    if (selectedActivity) {
      setActivityName(selectedActivity?.activity_name || "");
      setActivityDesc(selectedActivity?.activity_description || "");
      setActivityStatus(selectedActivity?.status || "not started");
      setActivityStart(selectedActivity?.start_date || "");
      setActivityEnd(selectedActivity?.end_date || "");
      setActivityBudget(selectedActivity?.activity_budget || "");
    }
  }, [selectedActivity]);

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <DeleteModal
        key={data?.id}
        id={`DeleteModal${data?.id}`}
        handleCancel={() => {
          window.document
            .getElementById(`DeleteModal${data?.id}`)
            .classList.replace("flex", "hidden");
        }}
        handleConfirm={() => {
          removeMilestone(data?.id ? data?.id : null);
          window.document
            .getElementById(`DeleteModal${data?.id}`)
            .classList.replace("flex", "hidden");
        }}
      />
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          Project Milestone {index + 1}
        </h1>

        <BsTrashFill
          className="mt-3 mr-3 h-4 w-4 cursor-pointer text-red-500"
          onClick={() => {
            window.document
              .getElementById(`DeleteModal${data?.id}`)
              .classList.replace("hidden", "flex");
          }}
        />
      </div>
      {/*  */}
      {loading ? (
        <Loading />
      ) : (
        <div className="m-3">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              {data?.milestone_name || "Milestone Name"}
            </span>
            <div className={`flex items-center gap-2`}>
              {!data?.id ? (
                <IoAddCircleOutline
                  className="h-5 w-5 cursor-pointer text-brand-600"
                  onClick={() => {
                    setShowMilestone(false);
                    setShowMileForm(!showMileForm);
                  }}
                />
              ) : (
                <MdEdit
                  color="#687182"
                  className="h-4 w-4 cursor-pointer "
                  onClick={() => {
                    setEditMilestone(true);
                    setShowMilestone(false);
                    setShowMileForm(true);
                  }}
                />
              )}
              <IoChevronDownCircleOutline
                color="#687182"
                className={`h-5 w-5 transform cursor-pointer ${
                  showMilestone ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setShowMilestone(!showMilestone);
                  setShowMileForm(false);
                }}
              />
            </div>
          </div>
          <MilestoneRow showMilestone={showMilestone} milestone={data} />

          <MilestoneRowForm
            edit={editMilestone}
            setEdit={setEditMilestone}
            data={data}
            setShowMilestoneForm={setShowMileForm}
            showMilestoneForm={showMileForm}
            handleSave={async (formData) => {
              handleMilestoneSave(
                formData,
                editMilestone ? data?.id : null
              ).then((res) => {
                if (res === true) {
                  removeMilestone();
                  setMilestoneSaved(true);
                  setShowMileForm(false);
                  setShowMilestone(true);
                }
              });
            }}
          />
          {(milestoneSaved || data.id) && (
            <div className="mb-2 mt-3 rounded-lg md:ml-14">
              <div className="flex items-center justify-between rounded-lg bg-white px-4 py-2 dark:bg-gray-200 ">
                <span className="text-sm text-gray-700 dark:text-gray-900 ">
                  Activities
                </span>
                <div className={`flex gap-1`}>
                  <IoAddCircleOutline
                    className="h-5 w-5 cursor-pointer text-brand-600"
                    onClick={() => {
                      setShowActivity(false);
                      setShowActivityForm(!showActivityForm);
                    }}
                  />
                  <IoChevronDownCircleOutline
                    color="#687182"
                    className={`h-5 w-5 transform cursor-pointer ${
                      showActivity ? "rotate-180" : "rotate-0"
                    }`}
                    onClick={() => {
                      setShowActivity(!showActivity);
                      setShowActivityForm(false);
                    }}
                  />
                </div>
              </div>
              <div
                className={`${
                  showActivity === true || showActivityForm === true
                    ? "block"
                    : "hidden"
                } px-1.5 py-2 text-gray-700`}
              >
                <div className="flex flex-col items-end text-center font-poppins text-sm text-[#707070] dark:text-white md:items-end">
                  {!showActivityForm ? (
                    <>
                      {size?.width <= 720 ? (
                        <div className="w-full">
                          {data?.activities?.map((activity, index) => {
                            return (
                              <ActivityRowCard
                                activity={activity}
                                key={index}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <table className="w-full table-auto rounded bg-white dark:bg-navy-900">
                          <thead>
                            <tr className="bg-[#F7F9FCCC] text-[#687182]">
                              <th></th>

                              <th className="text-start font-normal">
                                <p className="ml-4 max-w-[90px] truncate">
                                  {projectName || "Project Name"}
                                </p>
                              </th>
                              <th className="font-normal">Description</th>
                              <th className="font-normal">SA</th>
                              <th>
                                <div className="flex items-center justify-center gap-2 font-normal uppercase">
                                  <p>status</p>
                                  {/* <IoInformationCircle /> */}
                                </div>
                              </th>
                              <th className="ml-4 flex items-center justify-center gap-2 font-normal uppercase">
                                <p>Activity timeline</p>
                                {/* <IoInformationCircle /> */}
                              </th>
                              <th className="font-normal">Budget Estimation</th>
                              <th></th>
                            </tr>
                          </thead>
                          {data?.activities?.map((activity, index) => {
                            return (
                              <ActivityRow
                                key={activity?.id}
                                data={activity}
                                setSelectedActivity={(activity) => {
                                  setSelectedActivity(activity);
                                  setShowActivityForm(true);
                                }}
                              />
                            );
                          })}
                        </table>
                      )}
                    </>
                  ) : (
                    <form
                      id={activityFormId}
                      className="relative mt-2 w-full pb-6"
                      onSubmit={handleActitvitySubmit}
                    >
                      <ActivityRowForm
                        selectedActivity={selectedActivity}
                        activityName={activityName}
                        setActivityName={setActivityName}
                        activityStatus={activityStatus}
                        setActivityStatus={setActivityStatus}
                        activityStart={activityStart}
                        setActivityStart={setActivityStart}
                        activityEnd={activityEnd}
                        setActivityEnd={setActivityEnd}
                        activityBudget={activityBudget}
                        setActivityBudget={setActivityBudget}
                        activityDesc={activityDesc}
                        setActivityDesc={setActivityDesc}
                      />
                    </form>
                  )}
                  {showActivityForm && (
                    <div className="flex gap-6">
                      <button
                        className="-mb-3 rounded-md bg-gray-500 py-2 px-4 text-white"
                        onClick={() => {
                          setSelectedActivity(null);
                          setShowActivityForm(false);
                        }}
                      >
                        cancel
                      </button>
                      <button
                        className="-mb-3 rounded-md bg-brand-600 py-2 px-4 text-white"
                        type="submit"
                        form={activityFormId}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/*  */}
    </div>
  );
}

export default ProjectMilestone;
