import AllProjects from "components/icons/AllProjects";
import ComplexTable from "./components/ComplexTable";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import AssignedProjectsList from "views/admin/team-leader/components/AssignedProjectsList";
import Paginate from "components/pagination";
import { TLRouteNameContext } from "layouts/teamLeader";
import { useLocalStorage } from "@uidotdev/usehooks";

const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },

  {
    Header: "Project Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function ProjectsList() {
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const { setCurrentRoute, currentRoute } = useContext(TLRouteNameContext);
  const [user] = useLocalStorage("user");

  const [{ data: projects, loading, error }] = useAxios(
    {
      url: "/main_app/projects/",
      params: { page: page || 1 },
      method: "GET",
    },
    { useCache: false }
  );

  const tabs = [
    { title: "Created Projects", icon: <AllProjects /> },

    { title: "Assigned Projects", icon: <AllProjects /> },
  ];

  const activeColums =
    activeTab === 0
      ? columnsDataComplex.reduce((acc, item) => {
          if (item.Header === "Project Status") {
            return acc;
          }
          return [...acc, item];
        }, [])
      : columnsDataComplex;

  const tableData = projects?.results
    ?.filter((item) => {
      if (activeTab === 1) {
        return item["created_by"] !== user?.user;
      }
      if (activeTab === 2) {
        return item;
      }

      return true;
    })
    .map((item, index) => {
      if (activeTab === 0) {
        const newItem = { ...item, action: 0 };
        return newItem;
      }
      item["action"] = 1;
      return item;
    });

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Projects List") {
      setCurrentRoute("Projects List");
    }
    return () => {};
    // eslint-disable-next-line
  }, [setCurrentRoute, currentRoute]);

  return (
    <div className="mt-5">
      {/* Add project drawer */}

      <div className="mb-3 flex flex-wrap items-center justify-between">
        <div className="my-3 w-fit border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400">
          <ul className="-mb-px flex w-fit flex-wrap gap-5 lg:gap-16">
            {tabs.map((item, index) => (
              <li className="flex w-fit items-center" key={index}>
                <p
                  className={`flex cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {activeTab === 2 ? (
        <AssignedProjectsList
          projects={projects}
          page={page}
          setPage={setPage}
        />
      ) : (
        <>
          {!error && !loading && (
            <ComplexTable
              columnsData={activeColums}
              tableData={tableData || []}
            />
          )}
          <Paginate
            count={projects?.count || 0}
            page={page}
            setPage={(p) => {
              navigate(`/team-leader/projects?page=${p}`);
              setPage(p);
            }}
            next={() => {
              if (!projects?.next) {
                return;
              }
              navigate(`/team-leader/projects?page=${page + 1}`);
              setPage(page + 1);
            }}
            previous={() => {
              if (!projects?.previous) {
                return;
              }
              navigate(`/team-leader/projects?page=${page - 1}`);
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default ProjectsList;
