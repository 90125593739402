/* eslint-disable react-hooks/exhaustive-deps */
import AllProjects from "components/icons/AllProjects";
import ComplexTable from "./components/ComplexTableStaff";
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import Paginate from "components/pagination";
import { useContext, useEffect, useState } from "react";
import { CeoRouteNameContext } from "layouts/ceo";

const columnsDataComplextest = [
  {
    Header: "No",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "profile.first_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "roles[0].role.name",
  },

  {
    Header: "Account Status",
    accessor: "is_active",
  },
];

function StaffMembers() {
  const tabs = [{ title: "All Staff Memebers", icon: <AllProjects /> }];
  const [page, setPage] = useState(1);
  const { setCurrentRoute } = useContext(CeoRouteNameContext);

  const [{ data: staffMembers, loading, error }, refetch] = useAxios(
    {
      url: "/account/expert-users/",
      params: { page: page || 1 },
      method: "GET",
    },
    { useCache: false }
  );

  useEffect(() => {
    refetch();
    return () => {};
  }, [page]);

  useEffect(() => {
    setCurrentRoute("Staff Members");
    return () => {};
  }, []);

  return (
    <div className="mt-5">
      <div className="mb-3 flex items-center justify-between">
        <div className="my-3 border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400">
          <ul className="-mb-px flex flex-wrap">
            {tabs.map((item, index) => (
              <li className="flex items-center gap-2 me-2" key={index}>
                <p
                  className={`border-transparent flex cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600 dark:hover:text-gray-300 ${
                    index === 0
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "fill-[#84818A] dark:border-none"
                  }`}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {error && <Error error={error} />}

      {loading ? (
        <Loading />
      ) : (
        <>
          <ComplexTable
            columnsData={columnsDataComplextest}
            tableData={staffMembers || []}
          />
          <Paginate
            count={staffMembers?.count || 0}
            page={page}
            setPage={setPage}
            next={() => {
              if (!staffMembers?.next) {
                return;
              }
              setPage(page + 1);
            }}
            previous={() => {
              if (!staffMembers?.previous) {
                return;
              }
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default StaffMembers;
