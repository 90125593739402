import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { BsTrash } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import DeleteModal from "views/admin/expert/components/DeleteModal";

const ComplexTableDepartments = (props) => {
  const { columnsData, tableData, setSelectedProject } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto shadow"}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4 font-poppins">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="ml-5 border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                  >
                    <p className="pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "Department Name") {
                      data = (
                        <p
                          className="cursor-pointer pl-3 pr-2 text-sm text-navy-900 dark:text-white"
                          onClick={() =>
                            setSelectedProject(cell.row.original, true)
                          }
                        >
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "NO") {
                      data = (
                        <p className="pl-3 text-sm text-navy-900 dark:text-white">
                          {Number(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Description") {
                      data = (
                        <p className="pl-3 pr-2 text-sm  text-navy-900 dark:text-white">
                          {cell.value.length > 40
                            ? cell.value.slice(0, 40) + "..."
                            : cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <div className="flex items-center gap-3 text-gray-800 dark:text-white">
                          <DeleteModal
                            id={"project" + cell.row.original.id}
                            handleConfirm={() => {
                              props.handleDeleteProject(cell.row.original.id);
                              window.document
                                .getElementById(
                                  "project" + cell.row.original.id
                                )
                                .classList.replace("flex", "hidden");
                            }}
                            handleCancel={() => {
                              window.document
                                .getElementById(
                                  "project" + cell.row.original.id
                                )
                                .classList.replace("flex", "hidden");
                            }}
                          />

                          <button
                            onClick={() => {
                              setSelectedProject(cell.row.original);
                            }}
                          >
                            <FiEdit2 />
                          </button>
                          <button
                            onClick={() => {
                              window.document
                                .getElementById(
                                  "project" + cell.row.original.id
                                )
                                .classList.replace("hidden", "flex");
                            }}
                          >
                            <BsTrash />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="border-b px-0 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTableDepartments;
